import API from '../utils/api';

export default {
  name: 'FeatureReleaseInfoApi',
  methods: {
    async getAllFeatureReleaseInfo() {
      const path = '/features-release-info';
      return await new Promise((resolve, reject) => {
        API.get(path).then(
          (featureReleaseData) => {
            resolve(featureReleaseData);
          },
          (error) => {
            reject(error);
          },
        );
      });
    },
  },
};
