import axios from 'axios';
import API from '../utils/api';

export default {
  name: 'LearnApi',
  methods: {
    async getDomainsList() {
      const path = '/api2/learn/domains';
      // const headers = await getHeaders();
      return await new Promise((resolve, reject) => {
        API.get(path).then(
          (coursesList) => {
            resolve(coursesList.data);
          },
          (error) => {
            reject(error);
          },
        );
      });
    },

    async getCourseInfo(id) {
      const path = `/api2/learn/course?id=${id}`;
      return await new Promise((resolve, reject) => {
        API.get(path).then(
          (coursesList) => {
            resolve(coursesList.data);
          },
          (error) => {
            reject(error);
          },
        );
      });
    },

    async getCourseRecomendations(id) {
      const path = `/api2/learn/course-recommendation?id=${id}`;
      return await new Promise((resolve, reject) => {
        API.get(path).then(
          (coursesList) => {
            resolve(coursesList.data);
          },
          (error) => {
            reject(error);
          },
        );
      });
    },

    async getFellowLearnerns(courseId) {
      const path = `/api2/learn/fellow-learners?id=${courseId}`;
      return await new Promise((resolve, reject) => {
        API.get(path).then(
          (usersList) => {
            resolve(usersList.data);
          },
          (error) => {
            reject(error);
          },
        );
      });
    },

    async updateAssetUserStatus(statusInfo) {
      const path = `/api2/learn/user`;
      const payload = statusInfo;
      return await new Promise((resolve, reject) => {
        API.put(path, payload).then(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          },
        );
      });
    },

    async getAssetInfo(assetId, language) {
      let path;
      if (language) {
        path = `/api2/learn/asset?id=${assetId}&language=${language}`;
      } else {
        path = `/api2/learn/asset?id=${assetId}`;
      }
      return await new Promise((resolve, reject) => {
        API.get(path).then(
          (assetInfo) => {
            resolve(assetInfo.data);
          },
          (error) => {
            reject(error);
          },
        );
      });
    },

    async getCourseCompletionCertificate(courseId) {
      const path = `/api2/learn/certificate?id=${courseId}`;
      return await new Promise((resolve, reject) => {
        API.get(path).then(
          async (resp) => {
            const certificateInfo = resp.data;
            certificateInfo.certificate = `${process.env.VUE_APP_CERTIFICATE_DISTRIBUTION_URL}/${certificateInfo.certificate}`;
            certificateInfo.thumbnail = `${process.env.VUE_APP_CERTIFICATE_DISTRIBUTION_URL}/${certificateInfo.thumbnail}`;
            resolve(certificateInfo);
          },
          (error) => {
            reject(error);
          },
        );
      });
    },

    async getLearnTeamStats() {
      const path = `/api2/learn/team`;
      return await new Promise((resolve, reject) => {
        API.get(path).then(
          (teamStats) => {
            resolve(teamStats.data);
          },
          (error) => {
            reject(error);
          },
        );
      });
    },

    async getCertificatePDF(certificateId) {
      const path = `/api2/learn/certificate-pdf?id=${certificateId}`;
      return await new Promise((resolve, reject) => {
        API.get(path).then(
          async (resp) => {
            const certificateInfo = resp.data;
            certificateInfo.certificate = `${process.env.VUE_APP_CERTIFICATE_DISTRIBUTION_URL}/${certificateInfo.certificate}`;
            certificateInfo.certificate_pdf = `${process.env.VUE_APP_CERTIFICATE_DISTRIBUTION_URL}/${certificateInfo.certificate_pdf}`;
            certificateInfo.thumbnail = `${process.env.VUE_APP_CERTIFICATE_DISTRIBUTION_URL}/${certificateInfo.thumbnail}`;
            resolve(certificateInfo);
          },
          (error) => {
            reject(error);
          },
        );
      });
    },

    async getCertificatePdfBlob(certificateUrl) {
      const customHeaders = {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Max-Age': 0,
      };
      return await axios({
        headers: customHeaders,
        responseType: 'blob',
        method: 'GET',
        url: certificateUrl,
      });
    },
  },
};
