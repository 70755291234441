import API from '../utils/api';

export default {
  name: 'CustomRegistrationApi',
  methods: {
    async register(payload) {
      const path = '/custom-registration';
      return await new Promise((resolve, reject) => {
        API.post(path, payload).then(
          (res) => resolve(res),
          (error) => reject(error),
        );
      });
    },

    async validateRegistration(payload) {
      const path = '/custom-registration/validate';
      return await new Promise((resolve, reject) => {
        API.post(path, payload).then(
          (res) => resolve(res),
          (error) => reject(error),
        );
      });
    },

    async signUp(payload) {
      const path = '/custom-registration/signup';
      return await new Promise((resolve, reject) => {
        API.post(path, payload).then(
          (res) => resolve(res),
          (error) => reject(error),
        );
      });
    },

    async buyAdditionalLicenses(payload) {
      const path = '/custom-registration/additional-license';
      return await new Promise((resolve, reject) => {
        API.post(path, payload).then(
          (res) => resolve(res),
          (error) => reject(error),
        );
      });
    },
  },
};

export const registerBusinessPremiumOrStandardPlan = async function (
  payload,
  planType,
) {
  return await API.post(
    `/${planType === 'premium' ? 'premium' : 'standard'}-registration`,
    payload,
  );
};

export const getBusinessPremiumOrStandardRegistrationInfo = async function (
  payload,
  planType,
) {
  return await API.get(
    `/get-${planType === 'premium' ? 'premium' : 'standard'}-registration?id=${
      payload.id
    }`,
  );
};
