import { graphqlOperation } from 'aws-amplify';
import API from '../utils/api';
import { getTeamCount, getTeamUsers } from '../utils/api-helper';
import { getIntervalsList } from '../utils/common';
import { defaultFetchCount } from '../utils/constants';
import { TypeSenseServer } from '../pages/components/TypeSenseInit';
import { GraphQLService } from '../services/GraphQLService';
import { performSearch } from '../graphql/queries';

// eslint-disable-next-line no-unused-vars
const usersListDefaultParams = {
  minNumId: 1,
  maxNumId: defaultFetchCount.LIST_USERS,
};

const teamsListDefaultParams = {
  size: defaultFetchCount.TEAM_USERS,
  sort_column: 'id',
  sort_order: 'ASC',
  last_num_id: null,
  last_sort_value: '',
};

export default {
  name: 'usersApi',
  methods: {
    async getUserInfo() {
      const path = `/user`;
      return await new Promise((resolve, reject) => {
        API.get(path).then(
          (userInfo) => {
            resolve(userInfo.data);
          },
          (error) => {
            reject(error);
          },
        );
      });
    },

    async getTemplateContentsFlags(template) {
      const tsClient = await TypeSenseServer.getClient();
      const { hits } = await tsClient
        .collections('prezentations')
        .documents()
        .search({
          q: '*',
          filter_by: `theme:=[${template}]`,
          limit_hits: 1,
          per_page: 1,
          page: 1,
        });

      let slides = await GraphQLService.requestWithToken(
        graphqlOperation(performSearch, {
          parameters: JSON.stringify({
            andprefs: {
              theme: template,
              is_know: 'yes',
            },
            is_recommender_search: false,
            limit: 1,
          }),
        }),
      );
      slides = JSON.parse(slides.data.performSearch);
      return {
        hasBPGPresentations: !!hits.length,
        hasBPGSlides: !!slides.body.payloads.length,
      };
    },

    async updateUserByAdmin(userPayload, isSuperAdmin = false) {
      if (isSuperAdmin) {
        const superAdminFlag = { isOpBySuperAdmin: true };
        userPayload = { ...userPayload, superAdminFlag };
      }
      const path = `/user`;
      return await new Promise((resolve, reject) => {
        API.put(path, userPayload).then(
          (userInfo) => {
            resolve(userInfo.data);
          },
          (error) => {
            reject(error);
          },
        );
      });
    },

    async updateUserProfile(userPayload) {
      const path = 'user/profile';
      return await new Promise((resolve, reject) => {
        API.put(path, userPayload).then(
          (userInfo) => {
            resolve(userInfo.data);
          },
          (error) => {
            reject(error);
          },
        );
      });
    },

    async createUserByAdmin(userPayload, isSuperAdmin = false) {
      if (isSuperAdmin) {
        const superAdminFlag = { isOpBySuperAdmin: true };
        userPayload = { ...userPayload, superAdminFlag };
      }
      const path = '/user';
      return await new Promise((resolve, reject) => {
        API.post(path, userPayload).then(
          (userInfo) => {
            resolve(userInfo.data);
          },
          (error) => {
            reject(error);
          },
        );
      });
    },

    async getUsersList(params = usersListDefaultParams) {
      let path = '';
      if (params.maxNumId === null) {
        path = `/users/list?minNumId=${params.minNumId}`;
      } else {
        path = `/users/list?minNumId=${params.minNumId}&maxNumId=${params.maxNumId}`;
      }
      return await new Promise((resolve, reject) => {
        API.get(path).then(
          (usersList) => {
            resolve(usersList.data);
          },
          (error) => {
            reject(error);
          },
        );
      });
    },

    getTeamUsersList() {
      return new Promise((resolve, reject) => {
        getTeamCount().then(
          async (res) => {
            const { data } = res;
            if (data.totalCount > 0) {
              const list = getIntervalsList(
                data.minNumId,
                data.maxNumId,
                defaultFetchCount.LIST_USERS,
              ).map((info) => getTeamUsers(info.minIndex, info.maxIndex));

              const listBatch = list.reduce((resultArray, item, index) => {
                const chunkIdx = Math.floor(index / 6);

                if (!resultArray[chunkIdx]) {
                  resultArray[chunkIdx] = []; // start a new chunk
                }

                resultArray[chunkIdx].push(item);

                return resultArray;
              }, []);
              const totalUsers = [];
              for (const batch of listBatch) {
                try {
                  const r = await Promise.all(batch);
                  const t = r.reduce((prev, curr) => [...prev, ...curr], []);
                  totalUsers.push(...t);
                } catch (err) {
                  reject(err);
                }
              }
              resolve(totalUsers);
            }
          },
          (error) => {
            console.log(error);
            reject(error);
          },
        );
      });
    },

    async getTeamUsersListOLD(params = teamsListDefaultParams, teamUsers = []) {
      let path = `/users/myteam?sort_column=${params.sort_column}&sort_order=${params.sort_order}&size=${params.size}`;
      if (params.last_sort_value)
        path = `${path}&last_sort_value=${params.last_sort_value}`;
      if (params.last_num_id)
        path = `${path}&last_num_id=${params.last_num_id}`;
      const teamUsersResponse = await API.get(path);
      if (teamUsersResponse.data) {
        teamUsers.push(...teamUsersResponse.data);
        if (teamUsersResponse.nextToken.next) {
          const updatedParams = { ...teamsListDefaultParams };
          updatedParams.last_num_id = teamUsersResponse.nextToken.last_num_id;
          updatedParams.last_sort_value =
            teamUsersResponse.nextToken.last_sort_value;
          await this.getTeamUsersList(updatedParams, teamUsers);
        }
      }
      return teamUsers;
    },

    async search(
      query,
      { limit = 10, fields = [], filterBy = undefined } = {},
    ) {
      const primaryFields = [
        'id',
        'fullName',
        'firstName',
        'lastName',
        'email',
        'profileImage',
        'isActive',
      ];
      const tsClient = await TypeSenseServer.getClient();
      const { hits } = await tsClient
        .collections('users')
        .documents()
        .search({
          q: query,
          query_by: 'fullName,email',
          filter_by: filterBy,
          include_fields: [...primaryFields, ...fields].join(','),
          limit_hits: limit,
        });

      return hits;
    },
  },
};

export const getUsersListByTeamWithPagination = async (id, limit) => {
  try {
    const user = await API.get(
      `/users/list/byteam/${id}?minNumId=${limit}&maxNumId=${limit + 300}`,
    );
    return user;
  } catch (err) {
    return err;
  }
};
export const getTDData = async () => await API.get(`/api3/designer/theme`);

export const getUsersListByTeam = async (id) => {
  const usersByTeam = [];
  await API.get(`/users/count/byteam/${id}`)
    .then(async (resp) => {
      const { minNumId, maxNumId } = resp.data;
      const userListByTeamResponses = [];

      for (let i = minNumId - 1; i <= maxNumId; i += 300) {
        const userPromise = new Promise((resolve, reject) => {
          try {
            const usersInfo = getUsersListByTeamWithPagination(id, i);
            resolve(usersInfo);
          } catch (error) {
            reject(error);
          }
        });
        userListByTeamResponses.push(userPromise);
      }

      await Promise.all(userListByTeamResponses)
        .then((usersData) => {
          usersData.forEach((item) => {
            usersByTeam.push(...item.data);
          });
        })
        .catch((er) => {
          console.log(er);
        });
    })
    .catch((err) => {
      console.log(err);
    });
  return usersByTeam;
};

export const updateUser = async (userObj) => {
  try {
    const resp = await API.put('/user', userObj);
    return resp;
  } catch (err) {
    return err;
  }
};

export const createUser = async (userObj) => {
  try {
    const resp = await API.post('/user', userObj);
    return resp;
  } catch (err) {
    return err;
  }
};

export const getUsersListByCompanyWithPagination = async (
  id,
  limit,
  onlyTrialUsers,
) => {
  try {
    const user = await API.get(
      `/users/list/bycompany/${id}?minNumId=${limit}&maxNumId=${
        limit + 300
      }&only_trial_users=${onlyTrialUsers}`,
    );
    return user;
  } catch (err) {
    return err;
  }
};

export const getUsersListByCompany = async (id, onlyTrialUsers) => {
  const usersByCompany = [];
  await API.get(
    `users/count/bycompany/${id}?only_trial_users=${onlyTrialUsers}`,
  )
    .then(async (resp) => {
      const { minNumId, maxNumId } = resp.data;
      const userListByCompanyResponses = [];

      for (let i = minNumId - 1; i <= maxNumId; i += 300) {
        const userPromise = new Promise((resolve, reject) => {
          try {
            const usersInfo = getUsersListByCompanyWithPagination(
              id,
              i,
              onlyTrialUsers,
            );
            resolve(usersInfo);
          } catch (error) {
            reject(error);
          }
        });
        userListByCompanyResponses.push(userPromise);
      }

      await Promise.all(userListByCompanyResponses)
        .then((usersData) => {
          console.log('my user data', usersData);
          usersData.forEach((item) => {
            usersByCompany.push(...item.data);
          });
        })
        .catch((er) => {
          console.log(er);
        });
    })
    .catch((err) => {
      console.log(err);
    });
  return usersByCompany;
};

export const getUserById = async (id) => {
  try {
    const { data } = await API.get(`/user?id=${id}`);
    return data;
  } catch (err) {
    return err;
  }
};

export const getActiveUsersCountByTeam = async (id) => {
  try {
    const { data } = await API.get(`users/activecount/byteam/${id}`);
    return data;
  } catch (err) {
    return err;
  }
};

export const getOtherCompanyUsers = () =>
  API.get(`users/list/othercompanies?limit=5000&offset=0`).then(
    (resp) => resp.data.users,
  );

export const logLogin = async () => {
  const infoPopulator = {
    timezoneOffset() {
      return new Date().getTimezoneOffset() / 60;
    },
    browserTime() {
      return new Date();
    },
    browserVersion() {
      return navigator.userAgent;
    },
    browserLanguage() {
      return navigator.language;
    },
    browserPlatform() {
      return navigator.platform;
    },
    mobileDevice() {
      return navigator.userAgentData.mobile;
    },
    browserName() {
      const agent = window.navigator.userAgent.toLowerCase();
      switch (true) {
        case agent.indexOf('edge') > -1:
          return 'MS Edge';
        case agent.indexOf('edg/') > -1:
          return 'Edge ( chromium based)';
        case agent.indexOf('opr') > -1 && !!window.opr:
          return 'Opera';
        case agent.indexOf('chrome') > -1 && !!window.chrome:
          return 'Chrome';
        case agent.indexOf('trident') > -1:
          return 'MS IE';
        case agent.indexOf('firefox') > -1:
          return 'Mozilla Firefox';
        case agent.indexOf('safari') > -1:
          return 'Safari';
        default:
          return 'other';
      }
    },
    sizeScreenW() {
      return screen.width;
    },
    sizeScreenH() {
      return screen.height;
    },
  };

  const info = {};
  for (const [k, value] of Object.entries(infoPopulator)) {
    try {
      info[k] = value();
    } catch {
      info[k] = undefined;
    }
  }
  try {
    const resp = await API.post('/users/loglogin', info);
    return resp;
  } catch (err) {
    return err;
  }
};
