import API from '../utils/api';

export default {
  name: 'TrialApi',
  methods: {
    async trialRegistration(data) {
      const path = '/trial/register';
      return await new Promise((resolve, reject) => {
        API.post(path, data).then(
          (res) => resolve(res),
          (error) => reject(error),
        );
      });
    },
  },
};
