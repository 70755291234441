import API from '../utils/api';

export default {
  name: 'ECommerceActionsApi',
  methods: {
    async registerAction(ecommerceAction) {
      const path = '/api2/ecomm-action';
      return await new Promise((resolve, reject) => {
        API.post(path, ecommerceAction).then(
          (res) => resolve(res),
          (error) => reject(error),
        );
      });
    },
  },
};
