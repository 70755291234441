import API from '../utils/api';

export default {
  name: 'commonApi',
  methods: {
    async getThemesList() {
      const path = `/theme`;
      return await new Promise((resolve, reject) => {
        API.get(path).then(
          (themeInfo) => {
            resolve(themeInfo.data);
          },
          (error) => {
            reject(error);
          },
        );
      });
    },
  },
};

export const getCountries = async function () {
  return await API.get(`/api3/countries`);
};

export const getStates = async function (countryISO2) {
  return await API.get(`/api3/states?country_iso2=${countryISO2}`);
};
