import API from '../utils/api';

export default {
  name: 'EnterpriseSignupApi',
  methods: {
    getDetails(id) {
      const path = '/api2/enterprise/signup-validate';
      return API.get(path, { params: { id } });
    },
    signup(payload) {
      const path = '/api2/enterprise/signup';
      return API.post(path, payload);
    },
  },
};
