import API from '../utils/api';

async function performRestCall(path, body) {
  return await new Promise((resolve, reject) => {
    (body ? API.post(path, body) : API.get(path)).then(
      (pMethods) => {
        resolve(pMethods.data);
      },
      (error) => {
        reject(error);
      },
    );
  });
}

export default {
  name: 'PaymentApi',
  methods: {
    async getPaymentMethods() {
      const path = '/payment_methods';
      return performRestCall(path);
    },

    async getLicensePricing() {
      const path = '/license-pricing';
      return performRestCall(path);
    },

    async renewSubscription(body) {
      const path = '/renew';
      return performRestCall(path, body);
    },

    async upgradeSubscription(body) {
      const path = '/upgrade';
      return performRestCall(path, body);
    },

    async addLicenses(body) {
      const path = '/additional-seats-with-stripe';
      return performRestCall(path, body);
    },

    async getPricingPlans() {
      const path = '/plans/pricing';
      return performRestCall(path);
    },
  },
};

export const getSavedPaymentMethods = async function () {
  return await API.get('/payment_methods');
};

export const createNewPaymentMethod = async function (body) {
  return await API.post('/payment_method', body);
};

export const deletePaymentMethod = async function (paymentId) {
  return await API.delete(`/payment_method/${paymentId}`);
};

export const markPaymentMethodAsDefault = async function (body) {
  return await API.post('/payment_method/default', body);
};

export const updatePaymentMethodDetails = async function (body) {
  return await API.put('/payment_method', body);
};

export const getPaymentMethodById = async function (id) {
  return await API.get(`/payment_method?id=${id}`);
};

export const getBillingAddress = async function () {
  return await API.get('/api2/billing-address');
};

export const createBillingAddress = async function (payload) {
  return await API.post('/api2/billing-address', payload);
};

export const configureAutoRenew = async function (payload) {
  return await API.post('/configure-auto-renew', payload);
};

export const getTaxRate = async function (country, stateCode, zip) {
  let path = '/api2/tax-rate';
  const queryParams = [];

  if (country) {
    queryParams.push(`country=${encodeURIComponent(country)}`);
  }
  if (stateCode) {
    queryParams.push(`state=${encodeURIComponent(stateCode)}`);
  }
  if (zip) {
    queryParams.push(`zip=${encodeURIComponent(zip)}`);
  }

  if (queryParams.length > 0) {
    path = `${path}?${queryParams.join('&')}`;
  }

  return await API.get(path);
};
