import API from '../utils/api';
import { getIntervalsList } from '../utils/common';
import { defaultFetchCount } from '../utils/constants';
import { TypeSenseServer } from '../pages/components/TypeSenseInit';
import store from '../store';

/**
 * @typedef {("user"|"audience"|"private_audience"|"standard")} AudienceType
 */

/**
 * @typedef {Object} AudienceSearchPayload
 * @property {string} [query]
 * @property {string} [companyId]
 * @property {string} [userId]
 * @property {Record<string, any>} [realtimeAudiences]
 * @property {AudienceType[]} [searchAudienceTypes]
 * @property {string[]} [queryBy]
 * @property {string[]} [filterBy]
 * @property {{ users: string; audiences: string; private_audiences: string; standard_audiences: string }} [filterByCollection]
 * @property {string[]} [fields]
 * @property {string[]} [extraFields]
 * @property {number} [limit]
 */

export const audienceTypeCollectionMap = {
  user: 'users',
  audience: 'audiences',
  private_audience: 'private_audiences',
  standard: 'standard_audiences',
};

export default {
  name: 'AudienceApi',
  methods: {
    async createAudienceUser(id) {
      const path = `/audiences`;
      return await new Promise((resolve, reject) => {
        API.post(path, id).then(
          (res) => resolve(res),
          (error) => reject(error),
        );
      });
    },

    async createPrivateAudience(id) {
      const path = `/privateaudiences`;
      return await new Promise((resolve, reject) => {
        API.post(path, id).then(
          (res) => resolve(res),
          (error) => reject(error),
        );
      });
    },

    async validateAudience(uuid) {
      const path = `/audience/signin?uuid=${uuid}`;
      return await new Promise((resolve, reject) => {
        API.get(path).then(
          (validateResponse) => {
            resolve(validateResponse);
          },
          (error) => reject(error),
        );
      });
    },

    async getAudienceById(email) {
      const path = `/audience?email=${email}`;
      return await new Promise((resolve, reject) => {
        API.get(path).then(
          (audienceDetails) => {
            resolve(audienceDetails.data);
          },
          (error) => reject(error),
        );
      });
    },

    async createAudiencePheadrus(userObject) {
      const path = '/audience/create';
      return await new Promise((resolve, reject) => {
        API.post(path, userObject).then(
          (res) => resolve(res),
          (error) => reject(error),
        );
      });
    },

    async getAllAudiences() {
      const path = '/audiences/getall';
      return new Promise((resolve, reject) => {
        API.get(path).then(
          (audienceDetails) => {
            resolve(audienceDetails.Audiences);
          },
          (error) => reject(error),
        );
      });
    },

    async getCompanyNameByDomain(domainName) {
      const path = `/companybydomain/${domainName}`;
      return new Promise((resolve, reject) => {
        API.get(path).then(
          (domainNameDetails) => {
            resolve(domainNameDetails.data);
          },
          (error) => reject(error),
        );
      });
    },

    async getExternalCompanyNameByDomain(domainName) {
      const path = `/externalcompanybydomain/${domainName}`;
      return new Promise((resolve, reject) => {
        API.get(path).then(
          (domainNameDetails) => {
            resolve(domainNameDetails.data);
          },
          (error) => reject(error),
        );
      });
    },

    async getPrivateAudiences() {
      const path = '/privateaudiences';
      return new Promise((resolve, reject) => {
        API.get(path).then(
          (privateaudiencesDetails) => {
            resolve(privateaudiencesDetails.data.privateAudiences);
          },
          (error) => reject(error),
        );
      });
    },

    async deletePrivateAudience(id) {
      const path = `/privateaudience/${id}`;
      return new Promise((resolve, reject) => {
        API.delete(path).then(
          (data) => {
            resolve(data);
          },
          (error) => reject(error),
        );
      });
    },

    async getSharedFingerprintCount(type) {
      const path = `/shared-fingerprints/totalrecords?type=${type}`;
      return new Promise((resolve, reject) => {
        API.get(path).then(
          (sharedFingerprints) => {
            resolve(sharedFingerprints.data);
          },
          (error) => reject(error),
        );
      });
    },

    async getSharedFingerprints(type, minIndex, maxIndex) {
      let path = `/shared-fingerprints?minNumId=${minIndex}`;
      if (type !== 'private_audience') {
        path = `/shared-fingerprints/${type}?minNumId=${minIndex}`;
      }
      if (maxIndex) {
        path += `&maxNumId=${maxIndex}`;
      }
      return new Promise((resolve, reject) => {
        API.get(path).then(
          (sharedFingerprints) => {
            resolve(sharedFingerprints.data);
          },
          (error) => reject(error),
        );
      });
    },

    async getSharedFingerprintDetails(uuid, type = 'private_audience') {
      let path = `/fingerprint?fingerprint_id=${uuid}`;
      if (type) {
        path += `&type=${type}`;
      }

      return new Promise((resolve, reject) => {
        API.get(path).then(
          (sharedFingerprint) => {
            resolve(sharedFingerprint.data);
          },
          (error) => reject(error),
        );
      });
    },

    async getAudienceFingerprint(uuid) {
      const path = `audience-fingerprint?fingerprint_id=${uuid}`;
      return new Promise((resolve, reject) => {
        API.get(path).then(
          (sharedFingerprint) => {
            resolve(sharedFingerprint.data);
          },
          (error) => reject(error),
        );
      });
    },

    async requestFingerprintAccess(payload) {
      const path = '/api2/accessrequests';
      return await new Promise((resolve, reject) => {
        API.post(path, payload).then(
          (res) => resolve(res),
          (error) => reject(error),
        );
      });
    },

    async getAccessRequestIsDisabled(type, id) {
      const path = `/api2/getaccessrequest?uuid=${id}&type=${type}`;
      return API.get(path)
        .then((resp) => {
          if (resp.data && resp.data.length) {
            return resp.data[0].noOfDaysSinceRequested < 1;
          }
          return false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getAccessRequests() {
      const path = '/api2/accessrequests';
      return new Promise((resolve, reject) => {
        API.get(path).then(
          (accessRequests) => {
            resolve(accessRequests.data);
          },
          (error) => reject(error),
        );
      });
    },

    async getExternalFingerPrints() {
      const path = '/api2/external_fingerprint';
      return new Promise((resolve, reject) => {
        API.get(path).then(
          (externalFingerprints) => {
            resolve(externalFingerprints.data);
          },
          (error) => reject(error),
        );
      });
    },

    async approveOrDeclineAccessRequests(queryParam) {
      const path = `/api2/accessrequests${queryParam}`;
      return new Promise((resolve, reject) => {
        API.put(path).then(
          (accessRequests) => {
            resolve(accessRequests);
          },
          (error) => reject(error),
        );
      });
    },
    getSharedAudience(type) {
      return new Promise((resolve, reject) => {
        this.getSharedFingerprintCount(type).then((data) => {
          if (data.totalCount > 0) {
            const list = getIntervalsList(
              data.minNumId,
              data.maxNumId,
              defaultFetchCount.AUDIANCE_LIMIT,
            ).map((info) =>
              this.getSharedFingerprints(type, info.minIndex, info.maxIndex),
            );

            Promise.all(list).then(
              (response) => {
                const totalAudience = [];
                response.forEach((item) => {
                  if (item) totalAudience.push(...item);
                });
                resolve({ count: data, audiences: totalAudience });
              },
              (error) => {
                console.log(error);
                reject(error);
              },
            );
          } else {
            resolve({ count: 0, audiences: [] });
          }
        });
      });
    },

    async getPrezentationAudienceTypesense(
      audienceId,
      audienceOwnerId,
      { fields },
    ) {
      const searchAudienceTypes = audienceOwnerId
        ? ['private_audience']
        : ['user', 'audience', 'standard'];
      // PM-7168 Fix 2nd issue
      const field = 'email';

      const res = await this.audienceSearch({
        queryBy: [],
        searchAudienceTypes,
        filterBy: `${field}:[${audienceId}]`,
        fields,
      });

      const [audience] = res;

      return audience;
    },

    async getTypesenseAudience(email, type) {
      const tsClient = await TypeSenseServer.getClient();
      const collection = audienceTypeCollectionMap[type];
      const { found, hits } = await tsClient
        .collections(collection)
        .documents()
        .search({ q: '*', filter_by: `email:=[${email}]` });
      if (found) {
        return hits[0];
      }
      return null;
    },

    async getTypesenseUserAudience(companyId, userId) {
      const audience = await this.getTypesenseAudience(userId, 'user');

      if (audience) {
        return this.mapTypesenceAudience(audience, 'users', companyId, userId);
      }

      return null;
    },

    async getRealtimeAudiences(companyId, userId) {
      const idsUrl = 'api2/realtimeaudience/get';
      const { data: realtimeAudienceIds } = await API.get(idsUrl);

      const filter = {};

      realtimeAudienceIds.forEach((item) => {
        item.audienceType = item.audienceType.toLowerCase();

        const { audienceType, audienceID, isRecent, isTrending } = item;
        const collection = audienceTypeCollectionMap[audienceType];
        if (filter[collection]) {
          filter[collection].emails.push(audienceID);
          filter[collection].flags[audienceID] = { isRecent, isTrending };
        } else {
          filter[collection] = {
            emails: [audienceID],
            flags: { [audienceID]: { isRecent, isTrending } },
          };
        }
      });

      const tsClient = await TypeSenseServer.getClient();

      const searches = Object.keys(filter).map((collection) => {
        // wrapping all emails within `` so that typesense can negate special characters like '(' or ')' while parsing filters
        const emails = filter[collection].emails
          .map((eml) => `\`${eml}\``)
          .join(',');
        return {
          collection,
          q: '*',
          filter_by: `email:=[${emails}]`,
        };
      });

      const { results } = await tsClient.multiSearch.perform({ searches });

      const realtimeAudiences = [];

      searches.forEach(({ collection }, index) => {
        const searchResults = results[index];
        if (searchResults.hits && searchResults.hits.length) {
          const collectionAudiences = searchResults.hits.map((audience) => {
            const data = this.mapTypesenceAudience(
              audience,
              collection,
              companyId,
              userId,
            );

            const flags = filter[collection].flags[audience.document.email];

            return { ...data, ...flags };
          });

          realtimeAudiences.push(...collectionAudiences);
        }
      });

      return realtimeAudiences;
    },

    /**
     * audienceSearch
     * @param {AudienceSearchPayload} param0
     * @returns {Promise<any[]>}
     */
    async audienceSearch({
      query = '*',
      companyId,
      userId,
      realtimeAudiences = {},
      searchAudienceTypes = null,
      queryBy = ['firstName', 'lastName', 'email'],
      filterBy = '',
      filterByCollection = {},
      fields = null,
      extraFields = [],
      limit = 15,
    }) {
      const tsClient = await TypeSenseServer.getClient();

      let searches = {
        audiences: {
          collection: 'audiences',
          q: query,
          per_page: limit,
        },
        private_audiences: {
          collection: 'private_audiences',
          q: query,
          per_page: limit,
        },
        users: {
          collection: 'users',
          q: query,
          per_page: limit,
        },
        standard_audiences: {
          collection: 'standard_audiences',
          q: query,
          per_page: limit,
        },
      };

      Object.keys(filterByCollection).forEach((collection) => {
        searches[collection].filter_by = filterByCollection[collection];
      });
      searches = Object.values(searches);

      if (searchAudienceTypes) {
        // Logic to fetch private audiences by uuid or num_id
        if (
          searchAudienceTypes.length === 1 &&
          searchAudienceTypes[0] === 'private_audience'
        ) {
          const isPrivateAudienceUid = isNaN(Number(query));

          if (isPrivateAudienceUid) {
            queryBy.push('uuid');
          } else {
            filterBy = `id:[${query}]`;
            query = '*';
          }
        }

        searches = searchAudienceTypes.map((type) => ({
          collection: audienceTypeCollectionMap[type],
          q: query,
        }));
      }

      const includeFields = fields || [
        'id',
        'fullName',
        'firstName',
        'lastName',
        'email',
        'profileImage',
        'type',
        'accessible_to',
        'fingerPrint',
        'jobTitle',
        'isGeneratedEmail',
        'companyID',
        'companyName',
        'teamID',
        'privateAudienceType',
        'personalPreference',
        'isShared',
        'isPrivate',
        'subType',
        'ownerName',
        'emailEntered',
        'ownerID',
        'uuid',
        'storyPreference',
        'isActive',
      ];

      includeFields.push(...extraFields);

      const { results } = await tsClient.multiSearch.perform(
        {
          searches,
        },
        {
          query_by: queryBy.join(),
          filter_by: filterBy,
          include_fields: includeFields.join(),
          limit_hits: limit,
        },
      );

      const audiences = [];
      searches.forEach(({ collection }, index) => {
        const searchResults = results[index];
        const collectionAudiences = searchResults.hits.map((audience) => {
          const data = this.mapTypesenceAudience(
            audience,
            collection,
            companyId,
            userId,
          );
          return realtimeAudiences[data.audienceId] || data;
        });

        audiences.push(...collectionAudiences);
      });

      return audiences;
    },

    /**
     * mapAudience
     * @param {Object} audience
     * @param {'users' | 'audiences' | 'private_audiences' | 'standard_audiences'} collection
     */
    mapTypesenceAudience(audience, collection, companyId, userId) {
      const { document } = audience;
      switch (collection) {
        case 'users': {
          const userType =
            document.companyID === companyId ? 'user' : 'shared_user';
          const audienceId = `${document.email}-${userType}`;
          document.isPublicLimited = !(
            document?.accessible_to?.includes('a__0') ||
            document?.accessible_to?.includes(
              `c__${store.state.users.currentUser.company.num_id}`,
            )
          );
          const fullName =
            document.email === userId
              ? `${document.fullName} (Me)`
              : document.fullName;

          return {
            ...document,
            id: document.email,
            fullName,
            audienceId,
            num_id: Number(document.id),
            audienceType: 'user',
            shared: userType === 'shared_user',
          };
        }
        case 'audiences': {
          const audienceType =
            document.companyID === companyId ? 'audience' : 'shared_audience';
          const audienceId = `${document.email}-${audienceType}`;
          document.isPublicLimited = !(
            document?.accessible_to?.includes('a__0') ||
            document?.accessible_to?.includes(
              `c__${store.state.users.currentUser.company.num_id}`,
            )
          );
          return {
            ...document,
            id: document.email,
            audienceId,
            num_id: Number(document.id),
            audienceType: 'audience',
            shared: audienceType === 'shared_audience',
          };
        }
        case 'private_audiences': {
          const createdByMe = document.ownerID === userId;
          const privateAudienceType = createdByMe
            ? 'private_audience'
            : 'shared_private_audience';
          const audienceId = `${document.email}-${privateAudienceType}`;
          document.isPublicLimited = !(
            document?.accessible_to?.includes('a__0') ||
            document?.accessible_to?.includes(
              `c__${store.state.users.currentUser.company.num_id}`,
            )
          );

          return {
            ...document,
            id: document.id,
            audienceId,
            isGeneratedEmail: document.emailEntered === false,
            num_id: Number(document.id),
            audienceType: 'private_audience',
            isPrivate: document.isPrivate ? document.isPrivate : createdByMe,
            isGroup: document.privateAudienceType === 'Group',
            fingerprintType: document.subType,
            creator: createdByMe ? null : document.ownerName,
            shared: privateAudienceType === 'shared_private_audience',
          };
        }
        case 'standard_audiences': {
          const audienceId = `${document.email}-standard`;

          return {
            ...document,
            id: document.email,
            audienceId,
            num_id: Number(document.id),
            audienceType: 'standard',
            fingerprintType: 'Standard',
            creator: 'Prezent',
            profileImage: '/assets/img/standard-audience-dropdown-icon.svg',
          };
        }
        default:
          return null;
      }
    },
  },
};
